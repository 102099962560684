.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.container svg {
  overflow: visible;
}

.xAxis {
  color: #6b7a96;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 11px;
  text-anchor: middle;
}

.xAxis text {
  fill: currentColor;
}

.yAxis {
  color: #6b7a96;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 11px;
  text-anchor: middle;
}

.yAxis text {
  fill: currentColor;
}

.axisGridLine {
  stroke: #e6e8ed;
  stroke-width: 1.3;
  stroke-dasharray: 1, 3;
  stroke-linecap: round;
}

.chart {
  cursor: pointer;
}

.line-path {
  stroke-width: 2;
  fill: none;
}

.area {
  stroke: none;
  stroke-width: 0;
}
